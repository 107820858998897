<template>
    <div class="post">
         <h1>Not Found</h1>
       
    </div>
</template>

<script lang="js">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
            };
        }
    });
</script>
<style scoped>
</style>